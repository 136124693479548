.App{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  height: 100%;
}
.App::after{
  flex-grow: 2;
  content: "";
}
.App::before{
  flex-grow: 1;
  content: "";
}
.App-header{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  flex-grow: 0;
  padding-bottom: 44px;
}
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 60px;
  color: #0285AB;
  margin-top: 12px;
  margin-bottom: 12px;
  -webkit-text-stroke: 4px #cad1ce; /* width and color */
}
.logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;

}
.round {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #cad1ce;
  color: #232C24;
  border: 2px black solid;
}

.linkedin:hover {
  color: #0e76a8;
  background-color: #edf0ef;
  transition: color 100ms ease 100ms;
  transition: background-color 100ms ease 100ms;
}

.mail:hover {
  color: #0285AB;
  background-color: #edf0ef;
  transition: color 100ms ease 100ms;
  transition: background-color 100ms ease 100ms;
}

.github:hover {
  color: #000;
  background-color: #edf0ef;
  transition: color 100ms ease 100ms;
  transition: background-color 100ms ease 100ms;
}


.App-body {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  min-height: 350px;
  min-width: 250px;
  flex-grow: 0;
  padding-bottom: 40px;
}

.contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  gap: 30px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  gap: 30px;
}
.half-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
  padding-right: 20%;
  gap: 30px;
}
.card-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}

h2 {
  color: #aeb8b3;
  margin-bottom: 8px;
}
img {
  border: 3px #959E99 solid;
  width: 100%;
  height: 100%;
  min-height: 90px;
  min-width: 250px;
}


@media only screen and (max-width: 900px)  {
  .row {
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }
  .half-row {
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }
  .card-banner {
    width: 90%;
  }
}